import React, { useState, useRef, useEffect } from 'react'

// components
import Trail from 'src/components/Animation/TrailX'
import isVisible from 'src/utils/isVisible'
import useScroll from 'src/hooks/useScroll'

import {Section, Button} from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const CollectionOrangeNation = () => {
  const [open, setOpen] = useState(false)
  const scroll = useScroll(200)
  const sectionRef = useRef<HTMLElement>(null)
  const [ sendDatalayerEvent ] = useDataLayer()

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setOpen(true)
    }
  }, [ scroll ])

  return (
    <Section ref={sectionRef} className='py-5 d-flex align-items-end justify-content-center' id='colection'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <Trail open={open} to='right'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-40 fs-xl-40 lh-xl-50 text-white fw-600'>Coleção Orange Nation</h2>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>Uma parceria Inter e Nephew.</p>
              <Button
                href='/colecao-orange-nation'
                target='_blank'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Coleção Orange Nation',
                    element_action: 'click button',
                    element_name: 'Experimente a coleção Orange Nation',
                    redirect_url: 'https://www.orangenation.com.br/colecao-orange-nation/'
                  })
                }}
              >
                Experimente a coleção Orange Nation
              </Button>
            </Trail>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CollectionOrangeNation
