import React, {useState} from 'react'
import useDomReady from 'src/hooks/useDomReady'

import Layout from 'src/components/Layout'

import pageContext from './pageContext.json'

// Section
import Hero from './_sections/_hero/_index'
import DeclarationOfIndependence from './_sections/_declaration-of-independence/_index'
import OrangeNation from './_sections/_orange-nation/_index'
import NationOfIdeas from './_sections/_nation_of_ideias/_index'
import ToastToFreedom from './_sections/_toast-to-freedom/_index'
import CollectionOrangeNation from './_sections/_collection-orange-nation/_index'
// import OrangeOnNation from './_sections/_orange-on-nation/_index'
import MoreThanBank from './_sections/_more-than-a-bank/_index'

// Modal
import Modal from 'src/components/Modal'
import VipList from 'src/components/Forms/VipList'

import OpenAccount from 'src/components/Forms/OpenAccount'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const Home = () => {
  const [isModal, setIsModal] = useState(false)
  const [openVipModal, setOpenVipModal] = useState(false)
  const domReady = useDomReady()
  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_02',
    section_name: 'Declaração de Independência',
    element_action: 'click button',
    element_name: 'Fazer parte da Orange Nation',
  })

  const openModal = domReady && (
    <Modal isModalOpen={isModal} setIsModalOpen={setIsModal} locationToRender={document.body}>
      <OpenAccount
        setIsModalOpen={setIsModal}
        setOpenVipModal={setOpenVipModal}
        isModalOpen={isModal}
        dataLayer={dataLayer}
      />
    </Modal>
  )

  const vipModal = domReady && (
    <Modal isModalOpen={openVipModal} setIsModalOpen={setOpenVipModal} locationToRender={document.body}>
      <VipList setIsModalOpen={setOpenVipModal}/>
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModal}
      {vipModal}
      <Hero/>
      <DeclarationOfIndependence setIsModal={setIsModal} setDataLayer={setDataLayer} />
      <OrangeNation/>
      <NationOfIdeas/>
      <ToastToFreedom/>
      <CollectionOrangeNation/>
      {/* <OrangeOnNation/> */}
      <MoreThanBank setIsModal={setIsModal} setDataLayer={setDataLayer}/>
    </Layout>
  )
}

export default Home
