import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, red, white } from 'src/styles/colors'

// Images
import ToastToFreedomXs from 'src/assets/images/ToastToFreedomXs.gif'
import ToastToFreedomMd from 'src/assets/images/ToastToFreedomMd.gif'
import ToastToFreedomLg from 'src/assets/images/ToastToFreedomLg.gif'
import ToastToFreedomXl from 'src/assets/images/ToastToFreedomXl.gif'

export const Section = styled.section`
  background: ${grayscale[100]};
  overflow: hidden;
  height: 770px;
  background: url(${ToastToFreedomXs});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${red.dark};

  @media (min-width: ${breakpoints.md}){
    height: 488px;
    background: url(${ToastToFreedomMd});
    background-size: contain;
    background-repeat: no-repeat;
    background-color: ${red.dark};
  }

  @media (min-width: ${breakpoints.lg}){
    height: 453px;
    background: url(${ToastToFreedomLg});
    background-size: contain;
    background-repeat: no-repeat;
    background-color: ${red.dark};
  }

  @media (min-width: ${breakpoints.xl}){
    height: 640px;
    background: url(${ToastToFreedomXl});
    background-size: contain;
    background-repeat: no-repeat;
    background-color: ${red.dark};
  }
`

export const Button = styled.a`
  background: ${orange.base};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  font-weight: 600;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover{
    color: ${white};
    text-decoration: none;
    outline:none;
  }

  @media (min-width: ${breakpoints.md}){
    margin-top:32px;
  }

  @media (min-width: ${breakpoints.xl}){
    margin-top:42px;
  }
`

export const ImageContent = styled.div`
  max-width: 364px;

  @media (min-width: ${breakpoints.md}){
    max-width: 392px;
  }
  @media (min-width: ${breakpoints.lg}){
    img {
      max-width: 100%;
    }
  }
  @media (min-width: ${breakpoints.xl}){
    max-width: 533px;
  }
`
