import React, { useState, useRef, useEffect } from 'react'

// Style
import { Section, Button } from './style'

import Img from 'gatsby-image'

import pageQuery from 'src/pages/pageQuery'

import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'
import Trail from 'src/components/Animation/TrailX'
import isVisible from 'src/utils/isVisible'
import useScroll from 'src/hooks/useScroll'

// Json
import moreBankJSON from 'src/assets/data/more-than-bank.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type ModalProps ={
  setIsModal: Function
  setDataLayer: (state: IDataLayerParams) => void;
}

const MoreThanBank = ({setIsModal, setDataLayer}: ModalProps) => {
  const [open, setOpen] = useState(false)
  const scroll = useScroll(200)
  const sectionRef = useRef<HTMLElement>(null)
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Mais que um banco. Um Super App que simplifica a sua vida.',
    element_action: 'click button',
    element_name: 'Fazer parte da Orange Nation',
  }

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setOpen(true)
    }
  }, [ scroll ])

  return (
    <Section ref={sectionRef} className='py-5 d-flex align-items-center justify-content-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 d-none d-md-block'>
            <Trail open={open} to='right'>
              <Img fluid={data.moreThanBank.fluid}/>
            </Trail>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <Trail open={open} to='left'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-300'>Mais que um banco. <span className='fw-600 text-white'>Um Super App que simplifica a sua vida.</span></h2>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mt-4 mb-5'>Com o Inter, você tem um Super App de vantagens. Conta gratuita. Sem taxas. Com muito cashback.</p>
              <div className='row'>
                {
                  moreBankJSON.map(item => (
                    <div className='col-6'>
                      <div className='d-flex'>
                        <OrangeDsIcon icon={item.icon} size='MD' color='#fff'/>
                        <p className='fs-18 lh-22 text-white ml-3'>{item.text}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  setIsModal(open)
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
                }}
              >
                Fazer parte da Orange Nation
              </Button>
            </Trail>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default MoreThanBank
