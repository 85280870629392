import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

import BgHeroXl from 'src/assets/images/OrangeNationXl.png'
import BgHeroLg from 'src/assets/images/OrangeNationLg.png'
import BgHeroMd from 'src/assets/images/OrangeNationMd.png'
import BgHeroXs from 'src/assets/images/OrangeNationXs.png'
import { orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background-image: url(${BgHeroXs});
  background-repeat:no-repeat;
  background-size:cover;
  min-height:468px;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}){
    background-image: url(${BgHeroMd});
    background-repeat:no-repeat;
    background-size:cover;
    min-height:648px;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url(${BgHeroLg});
    background-repeat:no-repeat;
    background-size:cover;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image: url(${BgHeroXl});
    background-repeat:no-repeat;
    background-size:cover;
  }
`

export const Button = styled.button`
  width: 100%;
  height:48px;
  border:none;
  background: ${white};
  color: ${orange.base};
  border-radius:8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top:24px;

  &:focus{
    outline:none;
  }

  @media (min-width: ${breakpoints.md}){
    margin-top:32px;
  }

  @media (min-width: ${breakpoints.xl}){
    margin-top:40px;
  }
`

export const PlayVideo = styled.div`
  width:80px;
  height:80px;
  margin:0 auto 8px;
  cursor: pointer;
`
