import React, { useState, useRef, useEffect } from 'react'

// component
import TrailY from 'src/components/Animation/TrailY'
import isVisible from 'src/utils/isVisible'
import useScroll from 'src/hooks/useScroll'

import {Section, Button} from './style'

import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'

import pageQuery from 'src/pages/pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const ToastToFreedom = () => {
  const [open, setOpen] = useState(false)
  const scroll = useScroll(200)
  const sectionRef = useRef<HTMLElement>(null)
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setOpen(true)
    }
  }, [ scroll ])

  return (
    <Section ref={sectionRef} className='py-5 d-flex align-items-end align-items-md-center justify-content-center' id='drink'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-end'>
          <div className='col-12 col-md-6 col-xl-5 mt-5 mt-md-0'>
            <TrailY open={open}>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-4 text-white px-0'>Um brinde à liberdade</h2>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-4'>
                <span className='d-block'>Toda nação tem o seu drink.</span>
                  <span className='d-block'>Pra brindar momentos. Conquistas. Valores.</span>
                <span className='d-block'>O nosso drink tem gosto de ser livre. Tem cor de viver o que importa. </span>
                <span className='d-block'>Pra qualquer ocasião. Quando e como você quiser.</span>
              </p>
              <h3 className='fs-20 lh-25 text-white mb-4'>Experimente. O nosso drink é todo seu.</h3>
              <div className='d-flex align-items-center justify-content-center'>
                <div className='d-flex'>
                  <OrangeDsIcon icon='attention'  size='LG' color='#ff7a00' className='mr-3'/>
                </div>
                <p className='fs-12 lh-15 text-white mb-0'>Este produto é destinado a maiores de 18 anos. Beba com moderação. Se beber não dirija.</p>
              </div>
              <Button
                href='https://www.interstore.com.br/drink-orange-nation/p'
                target='_blank'
                title='Quero experimentar o drink'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Um brinde à liberdade',
                    element_action: 'click button',
                    element_name: 'Quero experimentar o drink',
                    redirect_url: 'https://www.interstore.com.br/drink-orange-nation/p'
                  })
                }}
              >
                Quero experimentar o drink
              </Button>
            </TrailY>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ToastToFreedom
