import React, { useState, useRef, useEffect } from 'react'

// Components
import Trail from 'src/components/Animation/TrailX'
import isVisible from 'src/utils/isVisible'
import useScroll from 'src/hooks/useScroll'

// styles
import { Section } from './style'

const NationOfIdeas = () => {
  const [open, setOpen] = useState(false)
  const scroll = useScroll(200)
  const sectionRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setOpen(true)
    }
  }, [ scroll ])


  return (
    <Section ref={sectionRef} className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <Trail open={open} to='right'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-5'>Uma nação de ideias e ações</h2>
            </Trail>
            <Trail open={open} to='left'>
              <h3 className='mb-4 fw-300'>
                <span className='text-orange--clearer'>mais de R$ </span>500 milhões
              </h3>
            </Trail>
            <Trail open={open} to='right'>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-4'>em cashback devolvidos</p>
            </Trail>
            <Trail open={open} to='left'>
              <h2 className='mb-4 fw-300'>
                <span className='text-orange--clearer'>R$</span> 7 bilhões
              </h2>
            </Trail>
            <Trail open={open} to='right'>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-4'>em tarifas não cobradas em 2021</p>
            </Trail>
            <Trail open={open} to='left'>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 fw-300'>Mais dinheiro no seu bolso. Mais liberdade pra sua vida.</p>
            </Trail>
            <Trail open={open} to='right'>
              <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 mt-4'>Isso é Nação Laranja.</h3>
            </Trail>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default NationOfIdeas
