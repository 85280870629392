import styled from 'styled-components'

export const TrailStyle = styled.div`
  .trailsText {
    position: relative;
    width: 100%;
    will-change: transform, opacity;
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`
