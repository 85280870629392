import React, { useState, useRef, useEffect } from 'react'

// Components
import Trail from 'src/components/Animation/TrailX'

// Styles
import {Section, Button} from './style'
import Img from 'gatsby-image'
import pageQuery from '../../pageQuery'

import isVisible from 'src/utils/isVisible'
import useScroll from 'src/hooks/useScroll'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type ModalProps = {
  setIsModal: Function;
  setDataLayer: (state: IDataLayerParams) => void;
}

const DeclarationOfIndependence = ({setIsModal, setDataLayer}: ModalProps) => {
  const [open, setOpen] = useState(false)
  const scroll = useScroll(200)
  const sectionRef = useRef<HTMLElement>(null)
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Declaração de Independência',
    element_action: 'click button',
    element_name: 'Fazer parte da Orange Nation',
  }

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setOpen(true)
    }
  }, [ scroll ])

  return (
    <Section ref={sectionRef} className='py-5 d-flex align-items-center justify-content-center' id='independence'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <Trail open={open} to='right'>
              <Img fluid={data.declarationOfIndependeceDesk.fluid} className='d-none d-md-block'/>
              <Img fluid={data.declarationOfIndependeceMobile.fluid} className='d-md-none'/>
            </Trail>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <Trail open={open} to='left'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-white fw-600 mb-4'>Declaração de Independência</h2>
              <p className='fs-16 lh-19 text-white'>A gente é muito parecido.
                <span className='fs-16 lh-19 text-white d-block'>Eu também cansei do óbvio.</span>
                <span className='fs-16 lh-19 text-white d-block'>Eu também tô aqui pra ser livre.</span>
              </p>
              <p className='fs-16 lh-19 text-white'>E quero viver a minha vida sem perder o meu tempo.
                <span className='fs-16 lh-19 text-white d-block'>Eu também quero crescer fazendo a coisa certa.</span>
                <span className='fs-16 lh-19 text-white d-block'>Você não tá sozinho. Você não tá sozinha.</span>
                <span className='fs-16 lh-19 text-white d-block'>A gente é muita gente. Milhões.</span>
              </p>
              <p className='fs-16 lh-19 text-white'>Livres pra não complicar o que é simples.
                <span className='fs-16 lh-19 text-white d-block'>Livres pra não pagar o que não precisa ser cobrado.</span>
                <span className='fs-16 lh-19 text-white d-block'>Pra não baixar que não precisa ser baixado.</span>
              </p>
              <p className='fs-16 lh-19 text-white'>Milhões. E milhões. E milhões. Livres.</p>
              <p className='fs-20 lh-25 text-white fw-600'>Essa é a Nação Laranja.</p>
              <Button
                onClick={() => {
                  setIsModal(true)
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
                }}
              >
                Fazer parte da Orange Nation
              </Button>
            </Trail>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default DeclarationOfIndependence
