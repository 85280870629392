import React, {ReactNode} from 'react'
import { useTrail, a } from '@react-spring/web'

import {TrailStyle} from './style'

type TrailProps = {
  open: boolean
  children: ReactNode
  to: string
}

const Trail = ({ open, children, to }: TrailProps) => {
  const items = React.Children.toArray(children)
  const position = to === 'left' ? 300 : -300

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200, restVelocity: 3000  },
    opacity: open ? 1 : 0,
    x: open ? 0 : position,
    height: open ? 'auto' : 0,
    from: { opacity: 0, x: position, height: 0 },
  })

  return (
    <TrailStyle>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className='trailsText' style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </TrailStyle>
  )
}

export default Trail
