import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  overflow: hidden;
  min-height: 506px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 458px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 474px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 484px;
  }
`
