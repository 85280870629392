import React, { useState, useRef, useEffect } from 'react'

// Components
import Trail from 'src/components/Animation/TrailX'

// Style
import {Section} from './style'
import isVisible from 'src/utils/isVisible'
import useScroll from 'src/hooks/useScroll'

const OrangeNation = () => {
  const [open, setOpen] = useState(false)
  const scroll = useScroll(200)
  const sectionRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setOpen(true)
    }
  }, [ scroll ])

  return (
    <Section ref={sectionRef} className='py-5' id='orangenation'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <Trail open={open} to='right'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-4'>Nação Laranja Inter</h2>
            </Trail>
            <Trail open={open} to='left'>
              <h3 className='fs-20 lh-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 mb-4'>
                <span className='text-white'>População:</span> 20 milhões.
              </h3>
            </Trail>
            <Trail open={open} to='right'>
              <p className='fs-14 lh-17 fs-xl-18 lh-xl-22 text-white mb-5'>20 milhões de pessoas unidas por um Super App com tudo o que você precisa. </p>
            </Trail>
            <Trail open={open} to='left'>
              <h3 className='fs-20 lh-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 mb-4'>
                <span className='text-white'>Território:</span> Onde você estiver.
              </h3>
            </Trail>
            <Trail open={open} to='right'>
              <p className='fs-14 lh-17 fs-xl-18 lh-xl-22 text-white'>A Nação Laranja é sobre ser livre pra não complicar o que é simples.</p>
            </Trail>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OrangeNation
