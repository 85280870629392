import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${grayscale[500]};
  overflow: hidden;
  min-height: 853px;

  @media(min-width: ${breakpoints.md}) {
    min-height: 632px;
  }
  @media(min-width: ${breakpoints.lg}) {
    min-height: 602px;
  }
  @media(min-width: ${breakpoints.xl}) {
    min-height: 677px;
  }
`

export const Button = styled.button`
  background: ${orange.base};
  width:100%;
  height:48px;
  border-radius:8px;
  color: ${white};
  font-size:14px;
  line-height:17px;
  border:none;
  margin-top:24px;

  &:focus{
    outline:none;
  }

  @media (min-width: ${breakpoints.md}){
    margin-top:32px;
  }

  @media (min-width: ${breakpoints.lg}){
    margin-top:40px;
  }
`
