import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
	background: ${orange.base};
	overflow: hidden;
	height: 376px;

	@media (min-width: ${breakpoints.md}) {
		min-height: 354px;
	}
	@media (min-width: ${breakpoints.lg}) {
		min-height: 376px;
	}
	@media (min-width: ${breakpoints.xl}) {
		min-height: 402px;
	}
`
