import React from 'react'
import Img from 'gatsby-image'

import OpenVideo from 'src/components/OpenVideo'
import Trail from 'src/components/Animation/TrailX'
import LinkTo from 'src/components/LinkTo'

import {Section, Button, PlayVideo} from './style'

import pegaQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Hero = () => {
  const data = pegaQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center justify-content-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
        <div
          className='col-12 col-md-6 col-lg-5 text-center mb-5 mb-md-0 order-md-last'
        >
          <OpenVideo link='https://www.youtube.com/embed/UnREGK6eAdY'>
            <PlayVideo
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Orange on nation',
                  element_action: 'click video',
                  element_name: 'Assista ao video',
                  redirect_url: 'https://www.youtube.com/embed/UnREGK6eAdY',
                })
              }}
            >
              <Img fluid={data.heroPlay.fluid}/>
            </PlayVideo>
            <p className='fs-16 lh-20 text-orange--base fw-600'><span className='d-block'>Assista</span> ao vídeo</p>
          </OpenVideo>
        </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <Trail open={true} to='right'>
              <Img fluid={data.heroLogo.fluid}/>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mt-4'>Aqui tem mais liberdade para simplificar a sua vida. Somos 20 milhões de pessoas livres e independentes, assim como o nosso Super App. </p>
              <LinkTo to='#drink'>
                <Button
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: 'Orange on nation',
                      element_action: 'click scroll',
                      element_name: 'Venha comemorar com a gente',
                    })
                  }}
                >
                  Venha comemorar com a gente
                </Button>
              </LinkTo>
            </Trail>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
