import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${grayscale[500]};
	overflow: hidden;
	height: 680px;

	@media(min-width: ${breakpoints.md}) {
		height: 690px;
	}
	@media(min-width: ${breakpoints.lg}) {
		height: 633px;
	}
	@media(min-width: ${breakpoints.xl}) {
		height: 700px;
	}
`

export const Button = styled.button`
	width:100%;
	height:48px;
	background: ${orange.base};
	border-radius:8px;
	border:none;
	color: ${white};
	font-size:14px;
	line-height:17px;
	font-weight:600;
	margin-top:27px;
	overflow: hidden;

	&:focus{
		outline:none;
	}

	@media (min-width: ${breakpoints.md}){
		margin-top:32px;
	}

	@media (min-width: ${breakpoints.lg}){
		margin-top:42px;
	}
`
