import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

import BgCollectionXs from '../../../assets/images/CollectionOrangeNationXs.png'
import BgCollectionMd from '../../../assets/images/CollectionOrangeNationMd.png'
import BgCollectionLg from '../../../assets/images/CollectionOrangeNationLg.png'
import BgCollectionXl from '../../../assets/images/CollectionOrangeNationXl.png'

export const Section = styled.section`
  background-image:url(${BgCollectionXs});
  background-repeat:no-repeat;
  background-size:100%;
  min-height:640px;
  background-color:#A12B20;
  overflow: hidden;
  
  @media (min-width: ${breakpoints.md}){
    background-image:url(${BgCollectionMd});
    background-repeat:no-repeat;
    background-size:100%;
    min-height:480px;
    background-color:#A12B20;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image:url(${BgCollectionLg});
    background-repeat:no-repeat;
    background-size:100%;
    min-height:678px;
    background-color:#A12B20;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image:url(${BgCollectionXl});
    background-repeat:no-repeat;
    background-size:100%;
    background-color:#A12B20;
  }
`

export const Button = styled.a`
  background:${white};
  width:100%;
  height:48px;
  border:none;
  border-radius:8px;
  font-size:14px;
  line-height:17px;
  font-weight:600;
  color: ${orange.base};
  margin-top:32px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor: pointer;

  &:focus, &:hover{
    outline:none;
    text-decoration:none;
  }

  @media (min-width: ${breakpoints.xl}){
    margin-top:40px;
  }
`
